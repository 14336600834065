<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Alcohol Management</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
        <div class="box-S2" >
           <div class="box-S2 noPadding">
              <div class="N-Page T-size-24">Filter</div>
            </div>
        </div>
        <div class="box-S2" >
          <div style="width:100%" v-if="this.userdata.r_id == 16">
            <a  href="https://shiftworkpilot.theprodigygroup.co.th/alcoholChecking" onclick="window.open(this.href,'_blank');return false;">
              <button
              style="float: right;"
                type="button"
                class="ColorTheme-S white--text theme-btn v-btn v-btn--flat v-btn--text theme--light v-size--default"
              >
                <span class="v-btn__content"><span>Checking Alcohol</span></span>
              </button>
            </a>
          </div>
        </div>

        <div class="box-S4">
          <div class="B-carddetail flex-between-center">
            <div class="box-S2 noPadding">
                 <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="hideMessage input-date"
                v-model="picker"
                label="Select Date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              type="date"
              @change="Render(picker)"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
            </div>
                        
            <div class="box-S2 noPadding">
              <v-text-field
                  label="Search"
                  v-model="search"
                  :readonly="readonly_eng || readonly_pilot || readonly_flight"
                ></v-text-field>
            </div>
          </div>
        </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        
        <v-data-table :headers="headers" :items="summarydata" :search="search" :items-per-page="-1" >
          <template v-slot:item.isAlcohol="{ item }">
          <v-simple-checkbox
            v-model="item.isAlcohol"
            disabled
          ></v-simple-checkbox>
          {{item.alcoholChecksDate}}
        </template>
          
        </v-data-table>
      </div>
    </div>
  </div>
 
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import MonthlyReport from "../utils/MonthlyReport"
import moment from "moment";
export default {
  data: () => ({
     headers: [
      { value: "fullnameEng", text: "Name", sortable: true },
      { value: "departmentName", text: "Department", sortable: true },
      { value: "shifDescription", text: "Work Time",  align: 'center',sortable: true },
      { value: "checkintagsMessage", text: "TimeIn-Out", align: 'center', sortable: true },
      { value: "isAlcohol", text: "Alcoholchecking", align: 'center', sortable: true },
    ],
    items: [],
    total: 0,
    loading: false,
    dialog: false,
    formModel: {},
    type: "month",
    picker: 0,
    dateSelect: new Date().toISOString().substr(0, 10),
    menu: false,
    // itemsListCompany: [],
    //Edit
    itemsListCustomer: [],
    // selectcompany:"",
    selectowner: "",
    ArrListDelayReport: [],
    DataDelayReport: [],
    //Add
    summarydata:[],
    search: '',
    userdata:[],

  }),
  computed: {},
  async mounted() {
    //Add
    this.userdata = this.$CurrentUser.GetCurrentUser();
    
    // this.SelectListCompany();
    this.picker = this.dateSelect;
    await this.Getsummary(this.picker);
    console.log(this.userdata)
  },

  methods: {
    async Getsummary(date){
    var a  = await this.$ProdigyFlyService.GetAlcoholAllDepartment(date);
    console.log(a);
    this.summarydata= a.data
    },
    async Render(date){
      await this.Getsummary(date);
    }
    
  },
};
</script> 


<style>
</style>